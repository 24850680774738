import ArticleCard from "@/common/cards/article/article"
import MarkingAreaCard from "@/common/cards/marking-area/marking-area"
import Slider from "@/common/slider/slider"
import Block from "@/components/wrappers/block"
import styles from "./post-slider.module.css"
import cx from "classnames"

export default function PostSlider({ block }) {
  const { cards, heading, theme, image, button, cardType } = block.attrs

  if (cards.length === 0) {
    return null
  }

  const wrapperClasses = [
    styles.postSlider,
    styles[theme],
    theme === "image" ? styles.overlay : ""
  ]
  let backgroundImage = {}

  if (image) {
    backgroundImage = { backgroundImage: "url(" + image.url + ")" }
  }

  return (
    <Block
      block={block}
      width="bleed"
      className={cx(wrapperClasses)}
      style={backgroundImage}>
      <Slider
        id={block.attrs.context}
        heading={heading}
        theme={theme}
        button={button}>
        {cards.map((post, index) => {
          let Component = null

          switch (cardType) {
            case "article":
              Component = ArticleCard
              break
            case "marking-area":
              Component = MarkingAreaCard
              break
          }

          return <Component key={index} post={post} />
        })}
      </Slider>
    </Block>
  )
}
